import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MeterInfoComponent } from '@app/energy/components/meter-info/meter-info.component';
import { adminMeterInfoForm } from '@app/energy/modals/admin-meter-info/admin-meter-info.constants';
import { EnergyUtils } from '@app/energy/utils/energy.utils';
import { FeaturePermission } from '@app/feature-scope/sandboxes/feature-permission.interface';
import { FeaturePermissionsSandbox } from '@app/feature-scope/sandboxes/feature-permissions.sandbox';
import { Move } from '@app/move/interfaces/move';
import { DateUtils, EnergyMetersState, FeatureScope, Role } from '@smooved/core';
import { SelectInput, SelectInputVariant } from 'projects/ui/src/lib/form/select-input/select-input';
import { startWith, take, takeUntil } from 'rxjs/operators';
import {
    defaultMeterInputRole,
    MeterInfoAlertType,
    meterInfoInputOptions,
    MeterInfoInputType,
    meterInputRoleOptions,
    mostChosenI18nKey,
} from './meter-info.constants';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';

@Component({
    selector: 'app-meter-info-container',
    templateUrl: './meter-info.container.html',
    styleUrls: ['./meter-info.container.scss'],
})
export class MeterInfoContainer extends MeterInfoComponent implements OnInit {
    @Input() public move: Move;
    @Input() public hideSubmit = false;
    @Input() public electricity: boolean;
    @Input() public gas: boolean;

    public readonly meterInfoInputType = MeterInfoInputType.Manual;
    public readonly meterInputRole = new UntypedFormControl(defaultMeterInputRole);
    public readonly meterInputRoles = Role;
    public readonly meterInfoForm = adminMeterInfoForm;
    public readonly meterInfoInputOptions = meterInfoInputOptions;
    public readonly selectInputVariants = SelectInputVariant;
    public readonly meterInfoAlertTypes = MeterInfoAlertType;

    public meterInputRoleOptions: SelectInput<Role>[];
    public meterReadingMaxDate = DateUtils.now();
    public meterReadingDisabled: boolean;

    public leaverMeterCollectionfeatureScopePermission: FeaturePermission;

    public readonly cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
    public readonly featurePermissionSandbox: FeaturePermissionsSandbox = inject(FeaturePermissionsSandbox);
    protected readonly uiSandbox: AppUiSandbox = inject(AppUiSandbox);

    constructor() {
        super();
    }

    public ngOnInit(): void {
        this.data = {
            move: this.move,
            readOnly: [EnergyMetersState.Confirmed, EnergyMetersState.Processed].includes(
                this.move?.track?.energyMeterReadings?.energyMetersState
            ),
            asTransactionType: this.move?.moveStates.transactionType,
        };

        super.ngOnInit();

        this.featurePermissionSandbox
            .getPermissions$(FeatureScope.LeaverMeterCollection, this.move)
            .pipe(take(1))
            .subscribe((permission) => {
                this.leaverMeterCollectionfeatureScopePermission = permission;
                this.meterInputRole.setValue(permission.canExecute ? Role.Mover : Role.RealEstateAgent);
            });

        this.form
            .get(adminMeterInfoForm.MovingDate)
            .valueChanges.pipe(startWith(this.move.movingDate), takeUntil(this.destroy$))
            .subscribe(this.handleMovingDateChanged);

        if (this.data.readOnly) {
            this.form.disable();
        }

        this.meterInputRole.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((role: Role) => {
            if (role === Role.RealEstateAgent) this.form.setErrors(null);
            else this.form.updateValueAndValidity();
        });
    }

    public onSubmit(): void {
        if (this.form.invalid) return;

        this.uiSandbox.showLoadingOverlay();

        super.onSubmit();
    }

    protected getPatchData(): Move {
        const movingDate = this.form.get(adminMeterInfoForm.MovingDate).value as Date;

        const moverInput = this.isInputByMover()
            ? {
                  ...(this.meterInfoElectricityComponent?.createPatch() || {}),
                  ...(this.meterInfoGasComponent?.createPatch() || {}),
              }
            : {};

        return {
            ...moverInput,
            movingDate,
        };
    }

    private handleMovingDateChanged = (value: Date): void => {
        this.enableMeterReadings(!this.data.readOnly && EnergyUtils.canEditMeterReadings(value));
    };

    private enableMeterReadings(enabled: boolean): void {
        enabled ? this.meterInputRole.enable() : this.meterInputRole.disable();
        this.meterReadingDisabled = !enabled;
        this.setMeterInputgRoleOptions();
        this.cdr.detectChanges();
    }

    private setMeterInputgRoleOptions(): void {
        this.meterInputRoleOptions = meterInputRoleOptions(!this.move?.moveStates.metersProcessedByAdmin && this.meterReadingDisabled);
        this.meterInputRoleOptions.forEach((option) => {
            if (option.value === this.meterInputRole.value) option.tag = mostChosenI18nKey;
        });
    }

    private isInputByMover(): boolean {
        return this.meterInputRole.value === Role.Mover;
    }
}
