<form
    class="u-flex-column u-flex-align-items-start u-w100p"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    *ngIf="{ isRealEstateAgentOrAdmin: (authenticationSandbox.isRealEstateAgentOrAdmin$ | async)} as vm"
>
    <ng-container *ngIf="moveTransactionType === moveTransactionTypes.Sale">
        <h6 class="u-color-muted u-margin-bottom u-margin-top">{{ 'ENERGY.YOUR_TRANSFER' | translate }}</h6>
        <app-moving-date-input
            formControlName="movingDate"
            [label]="'MOVE.DEED_DATE' | translate"
            class="u-margin-bottom-double"
        ></app-moving-date-input>
    </ng-container>

    <h6 class="u-color-muted u-margin-bottom">{{ 'METER_READINGS' | translate }}</h6>

    <app-select-input
        [custom]="false"
        [hasMargin]="false"
        [hasMarginDouble]="false"
        class="__select-input-type u-margin-bottom u-margin-bottom-triple"
        [formControl]="meterInputRole"
        [options]="meterInputRoleOptions | translateProp"
        [variant]="selectInputVariants.Grouped"
    ></app-select-input>

    <ng-container *ngIf="meterInputRole.value === meterInputRoles.Mover">
        <ui-toggle-group
            [(value)]="meterInfoInputType"
            [disabled]="isDisabled"
            [options]="meterInfoInputOptions | translateProp"
            class="u-margin-bottom-double"
        ></ui-toggle-group>

        <!-- use hidden, otherwise this component will not set the electricity values -->
        <div [hidden]="!meterInfoInputType">
            <app-meter-info-electricity
                [hidden]="!electricity"
                class="__electricity"
                layout="vertical"
                [disabled]="isDisabled"
                [readingDisabled]="meterReadingDisabled"
                [move]="data.move"
                [show]="electricity"
                [movingDate]="form.get(meterInfoForm.MovingDate).value"
                (meterTypeChange)="meterInfoGasComponent?.meterTypeIsDigitalListener($event)"
            >
            </app-meter-info-electricity>

            <!-- use hidden, otherwise this component will not set the gas values -->
            <app-meter-info-gas
                [hidden]="!gas"
                [move]="data.move"
                [show]="gas"
                [disabled]="isDisabled"
                [readingDisabled]="meterReadingDisabled"
                class="u-margin-bottom"
            ></app-meter-info-gas>
        </div>

        <div [hidden]="meterInfoInputType">
            <app-energy-meter-reading-assets-input
                [move]="data.move"
                [readOnly]="(isDisabled || meterReadingDisabled) && !vm.isRealEstateAgentOrAdmin"
                class="u-margin-bottom-triple"
                (detail)="onEnergyMeterReadingDetail($event)"
            >
            </app-energy-meter-reading-assets-input>
        </div>
    </ng-container>

    <div class="u-text-align-left u-w100p u-margin-top-double" *ngIf="!hideSubmit && (!isDisabled || vm.isRealEstateAgentOrAdmin)">
        <app-previous-submit [showPrevious]="false" [showCancel]="false" submitTranslationLabel="SUBMIT"></app-previous-submit>
    </div>
</form>
